<template>
  <div class="section-wrapper">
    <b-breadcrumb>
    <b-breadcrumb-item to="/dashboard">
      <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
      Dashboard
    </b-breadcrumb-item>
    <b-breadcrumb-item to="/book-cover-page">Book Cover Page</b-breadcrumb-item>
    <b-breadcrumb-item active>Add New Cover Page Content</b-breadcrumb-item>
  </b-breadcrumb>
    <b-card>
      <b-card-title>
        <b-row>
          <b-col>
            <h4 class="card-title mb-0 pl-0">Cover Page Content</h4>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="info" @click="previousRouter()"><i class="ri-arrow-left-line"></i>Back</b-button>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-text>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Position" vid="position" rules="required">
                    <b-form-group
                      label-for="position"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      Position
                    </template>
                    <b-form-select
                      plain
                      v-model="form.position"
                      :options="coverPositionList"
                      id="position"
                      :state="errors[0] ? false : (valid ? true : null)"
                      placeholder="Select postion"
                      >
                    <template v-slot:first>
                    </template>
                    </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Release Date" vid="release_date" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-for="release_date">
                    <template v-slot:label>
                      Release Date
                    </template>
                    <flat-pickr
                      id="release_date"
                      v-model="form.release_date"
                      class="form-control"
                      placeholder="Select Date"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :config="flatPickrConfig"
                    />
                    <div class="d-block invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Edition" vid="edition" rules="required" v-slot="{ errors }">
                  <b-form-group
                    class="row"
                    label-cols-sm="12"
                    label-for="edition"
                  >
                  <template v-slot:label>
                    Edition <span>*</span>
                  </template>
                    <b-form-input
                      id="edition"
                      v-model="form.edition"
                      placeholder="Enter Edition"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Photo" vid="Photo">
                  <b-form-group
                    label-for="photo"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    Cover Photo
                    <img v-if="attachtedFileUrl" :src="attachtedFileUrl" class="mr-2" width="70" alt="Profile Image">
                    <img v-if="!attachtedFileUrl && form.cover_photo" :src="form.cover_photo" class="mr-2" width="70" alt="Profile Image">
                  </template>

                  <b-form-file
                    class="mt-3"
                    id="photo"
                    v-on:change="onFileChange"
                    accept="image/*"
                    ref="file"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-file>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="12" md="12" sm="12" xs="12">
                <ValidationProvider name="Page Content" vid="`page_content$(index)`" rules="required">
                  <b-form-group
                    label-cols-sm="12"
                    vid="`page_content$(index)`"
                    slot-scope="{ valid, errors }"
                    >
                  <template v-slot:label>
                    Page Content
                  </template>
                  <vue2-tinymce-editor
                  id="page_content"
                  :width:="500" :height="300"
                  v-model="form.page_content"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :options="options"
                  >
                  </vue2-tinymce-editor>
                  <div class="d-block invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-right">
                <b-button type="submit" variant="success" class="mr-2 btn-sm">Complete & Save</b-button>
              </b-col>
            </b-row>
          </b-form>
       </ValidationObserver>
      </b-card-text>
    </b-card>
    <!-- <pre>{{  form }}</pre> -->
  </div>
</template>
<script>
// import the component
import 'vue-select/dist/vue-select.css'
import RestApi, { apiBaseURL } from '@/config'
import { Vue2TinymceEditor } from 'vue2-tinymce-editor'
import axios from 'axios'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'

export default {
  name: 'Content',
  components: {
    Vue2TinymceEditor,
    flatPickr
  },
  data () {
    return {
      form: {
        id: '',
        position: '',
        edition: '',
        release_date: '',
        page_content: '',
        active: '',
        cover_photo: '',
        photo: ''
      },
      coverPositionList: [
        { value: 'frontpage', text: 'Front Page' },
        { value: 'backpage', text: 'Back Page' }
      ],
      attachtedFileUrl: '',
      attachtedFile: '',
      sectionParentList: [],
      value: null,
      valid: null,
      pageNo: null,
      options: {
        // menubar: false,
        table_column_resizing: 'resizetable',
        // toolbar: 'bold italic underline | addcomment showcomments',
        // menubar: 'file edit view insert format tools tc',
        // plugins: 'paste code tinycomments',
        // menu: {
        //   tc: {
        //     title: 'Comments',
        //     items: 'addcomment showcomments deleteallconversations'
        //   }
        // },
        // plugins: 'image code editimage',
        // images_upload_url: 'postAcceptor.php'
        images_upload_handler: this.image_upload_handler
        // images_upload_handler: function (blobInfo, success, failure) {
        //   console.log('blobInfo', blobInfo)
        //   console.log('success', success)
        //   setTimeout(function () {
        //     success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png')
        //   }, 2000)
        // }
        // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        // toolbar1: 'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        // toolbar2: 'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code'
      },
      flatPickrConfig: {
        dateFormat: 'd-m-Y'
      }
    }
  },
  created () {
    // this.id = this.hash_id(this.$route.params.id, false)[0]
  },
  mounted () {
    const id = this.hash_id(this.$route.params.id, false)[0]
    if (id) {
      this.getBookCoverPageContentById(id)
    }
  },
  computed: {
  },
  methods: {
    image_upload_handler: async function (blobInfo, success, failure) {
      console.log('blobInfo', blobInfo)
      console.log('success', success)
      console.log('failure', failure)

      const formData = new FormData()
      formData.append('photo', blobInfo.blob(), blobInfo.filename())
      formData.append('page_no', this.pageNo)

      const accessToken = localStorage.getItem('access_token')

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          accessMenuName: window.location.href,
          'Content-Type': 'multipart/form-data'
        }
      }

      const result = await axios.post(`${apiBaseURL}admin/ajax/upload_book_content_photo`, formData, axiosConfig)
      console.log('result', result)
      if (result.status === 200 && result.data.success === true) {
        success(result.data.location)
      } else {
        failure(result.data.message)
      }
      // return axios.post(`${apiBaseURL}admin/ajax/upload_book_content_photo`, postData, axiosConfig)
    },
    onFileChange (event) {
      const inputFile = event.target.files[0]
      const inputFilePath = event.target.value

      const reader = new FileReader()
      console.log('inputFile', inputFile)
      if (inputFile.size < 5242880) {
        const allowedExtensions = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i

        if (!allowedExtensions.exec(inputFilePath)) {
          this.alert('File type should be jpg/jpeg/png/gif!', 'error')
          return false
        }

        reader.onloadend = () => {
          this.attachtedFileUrl = reader.result
        }
        reader.readAsDataURL(inputFile)
        this.attachtedFile = inputFile
      } else {
        this.alert('File size should be maximus 5MB!', 'error')
      }
    },
    async submitData () {
      this.loading = true
      let result = ''
      var formData = new FormData()
      Object.keys(this.form).map(key => {
        formData.append(key, this.form[key])
      })
      if (this.attachtedFile) {
        formData.append('photo', this.attachtedFile)
      }
      if (this.form.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_book_cover_page_data', formData)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_book_cover_page_data', formData)
      }
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$router.push('/book-cover-page')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    previousRouter () {
      this.$router.push('/book-cover-page')
    },
    goToComp () {
      this.$router.push('/book-page')
    },
    async getBookCoverPageContentById (id) {
      const params = Object.assign({}, { id })
      const result = await RestApi.getData(apiBaseURL, 'admin/ajax/get_book_cover_page_content_by_id', params)
      if (result.success) {
        this.form = result.data
        this.form.release_date = moment(result.data.release_date).format('DD-MM-YYYY')
      }
    }
  }
}
</script>
<style>
</style>
